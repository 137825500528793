import { useEffect, useState } from "react";
import { Directus } from "@directus/sdk";
import { Route } from "wouter";
import Nav from "../../elements/nav/Nav";
import Home from "../home/Home";
import "./App.css";

import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import Loader from "../../basics/loader/Loader";
import Lessen from "../lessen/Lessen";
import Header from "../../elements/header/Header";
import Footer from "../../elements/footer/Footer";
import Privacy from "../privacy/Privacy";
import Blog from "../blog/Blog";
import Blogitem from "../blogitem/Blogitem";
import Button from "../../basics/button/Button";
import Gallerij from "../gallerij/Gallerij";

const directus = new Directus(process.env.REACT_APP_BACKEND_URL);

function ErrorFallback({ error }) {
  return (
    <div className="container">
      <div className="error" role="alert">
        <p className="bigText">Oops, er ging iets verkeerd:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
        <p>Als deze fout blijft voorkomen, neem gerust contact op. </p>
        <p>Druk op de reset-knop om de website te herladen...</p>
        <div className="errorAction">
          <Button
            primary={true}
            label="Reset"
            onClick={() => {
              window.location.reload();
            }}
          />
        </div>
      </div>
    </div>
  );
}

function App() {
  const [bgImage, setBgImage] = useState("");
  const [subImage, setSubImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!loading && !success) {
      setLoading(true);
      directus
        .singleton("Home_background")
        .read()
        .then((result) => {
          setSuccess(true);
          setBgImage(
            process.env.REACT_APP_BACKEND_URL + "assets/" + result.Afbeelding
          );
          setSubImage(
            process.env.REACT_APP_BACKEND_URL +
              "assets/" +
              result.Subpagina_achtergrond
          );
          setLoading(false);
        }).catch(err => {
          setSuccess(true);
          setBgImage(
            "/imgs/img-bg-lilis-sport.jpg"
          );
          setSubImage(
            "/imgs/sport-gratis-lessen.JPEG"
          );
          setLoading(false);
          console.log(err);
        });
    }
  }, [bgImage, loading, success]);

  return !success ? (
    <div className="AppLoader">
      <div className="Loader">
        <Loader />
        <p>Binnenkort ben jij sneller dan deze website...</p>
      </div>
    </div>
  ) : (
    <HelmetProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className="App">
          <Nav />
          <Route path="/">
            <Home bgImage={bgImage} />
          </Route>
          <Route path="/lessen">
            <Header backgroundImage={subImage} title="Aanbod" />
            <Lessen />
            <Footer />
          </Route>
          <Route path="/gallerij">
            <Header backgroundImage={subImage} title="Gallerij" />
            <Gallerij />
            <Footer />
          </Route>
          <Route path="/blog/:id">
            <Blogitem backgroundImage={subImage} />
            <Footer />
          </Route>
          <Route path="/blog">
            <Header backgroundImage={subImage} title="Blog" />
            <Blog />
            <Footer />
          </Route>
          <Route path="/contact"> contact </Route>
          <Route path="/privacy-policy">
            <Header backgroundImage={subImage} title="Privacy policy en algemene voorwaarden/huisregels" />
            <Privacy />
            <Footer />
          </Route>
        </div>
      </ErrorBoundary>
    </HelmetProvider>
  );
}

export default App;
