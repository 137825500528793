import { Directus } from "@directus/sdk";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";
import Container from "../../basics/container/Container";
import Loader from "../../basics/loader/Loader";
import styles from "./Blog.module.css";

const directus = new Directus(process.env.REACT_APP_BACKEND_URL);

const Blog = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useLocation();
  const [blogs, setBlogs] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!loading && !success) {
      setLoading(true);
      directus
        .items("Blog")
        .readByQuery({ sort: ["date_created"], limit: 5, fields: ["*"] })
        .then((result) => {
          setSuccess(true);
          const blogs = [];
          // transform data
          result.data.forEach((res) => {
            let blog = {};
            blog.title = res.Titel;
            blog.intro = res.Introductie;
            const createDate = new Date(res.date_created);
            blog.content = res.Inhoud;
            blog.createdDate = createDate;
            // const options = {day: "numeric", month: "short"};
            let smallDateDay = new Intl.DateTimeFormat("nl-NL", {
              day: "numeric",
            }).format(createDate);
            let smallDateMonth = new Intl.DateTimeFormat("nl-NL", {
              month: "short",
            }).format(createDate);
            blog.smalldateday = smallDateDay;
            blog.smalldatemonth = smallDateMonth;

            blog.id = res.id;

            blogs.push(blog);
          });
          setBlogs(blogs);
          setLoading(false);
        }).catch(err => {
          setLoading(false);
          setBlogs([]);
          setSuccess(true);

          console.log(err);
        });
    }
  }, [blogs, loading, success, location]);

  if (success === true) {
    return (
      <Container>
        {blogs.length === 0 ? <h3>Geen blog artikels, maar kom binnenkort nog eens terug.</h3> : null}
        {blogs.map((blog) => {
          return (
            <div
              key={blog.id}
              className={styles.blogitem}
              onClick={() => {
                setLocation("/blog/" + blog.id);
              }}
            >
              <div className={styles.blogdate}>
                <div className={styles.blogday}>{blog.smalldateday}</div>
                {blog.smalldatemonth}
              </div>
              <div className={styles.blogcontent}>
                <h2 className={styles.blogtitle}>{blog.title}</h2>
                <div className={styles.blogintro}>{blog.intro}</div>
              </div>
              <div className={styles.readmore}>Lees meer...</div>
            </div>
          );
        })}
      </Container>
    );
  } else {
    return (
      <div className={styles.loadWrapper}>
        <Loader />
        <div>De artikels worden ingeladen...</div>
      </div>
    );
  }
};

export default Blog;
