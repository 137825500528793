import styles from "./Button.module.css";

const Button = (props) => {
  const handleClick = () => {
    if (props.onClick !== undefined) {
      props.onClick(props.label);
    }
  };

  if (props.disabled) {
    return (
      <button className={styles.disabled} disabled={true}>
        {props.label}
      </button>
    );
  } else {
    return (
      <button
        className={props.primary ? styles.primary : styles.secondary}
        onClick={() => {handleClick()}}
      >
        {props.label}
      </button>
    );
  }
};

export default Button;
