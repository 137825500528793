import { Link } from "wouter";
import Container from "../../basics/container/Container";
import styles from "./Footer.module.css";

const Footer = (props) => {
  return (
    <footer>
      <Container>
        <div className={styles.footerContainer}>
          <div>
            <p>Contact: </p>
            <p>
              <a
                rel="noreferrer"
                target="_blank"
                href="mailto:fitmom.liesbeth.1981@gmail.com"
              >
                E-mail
              </a>
              <br />
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/groups/1341543803013300"
              >
                Facebook
              </a>
              <br />
              <a
                rel="noreferrer"
                target="_blank"
                href="https://linktr.ee/fitcoach_mom_Liesbeth"
              >
                Instagram
              </a>
            </p>
          </div>
          <div className={styles.privacy}>
            <Link href="/privacy-policy">Privacy policy en algemene voorwaarden/huisregels</Link>
            <br />
            &copy; {new Date().getFullYear()}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
