import { Directus } from "@directus/sdk";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Container from "../../basics/container/Container";
import Loader from "../../basics/loader/Loader";
import Card from "../../elements/card/Card";
import SubscribeModal from "../../elements/subscribemodal/SubscribeModal";
import styles from "./Lessen.module.css";

const directus = new Directus(process.env.REACT_APP_BACKEND_URL);

const Lessen = (props) => {
  const [activeLesson, setActiveLesson] = useState({});
  const [lessons, setLessons] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [temp, setTemp] = useState(false);

  useEffect(() => {
    if (!loading && !success) {
      setLoading(true);
      directus
        .items("Evenementen")
        .readByQuery({ sort: ["Start_datum_tijd"], fields: ["*"] })
        .then((result) => {
          setSuccess(true);
          const lessons = [];
          // transform data
          result.data.forEach((res) => {
            let lesson = {};
            lesson.title = res.Titel;
            const startDate = new Date(res.Start_datum_tijd);
            const now = new Date();
            if (startDate >= now) {
              const endDate = new Date(res.Eind_datum_tijd);
              // const options = {day: "numeric", month: "short"};
              let smallDateDay = new Intl.DateTimeFormat("nl-NL", {
                day: "numeric",
              }).format(startDate);
              let smallDateMonth = new Intl.DateTimeFormat("nl-NL", {
                month: "short",
              }).format(startDate);
              let startFormat = new Intl.DateTimeFormat("nl-NL", {
                // dateStyle: "short",
                timeStyle: "short",
              }).format(startDate);
              let endFormat = new Intl.DateTimeFormat("nl-NL", {
                timeStyle: "short",
              }).format(endDate);
              lesson.smalldateday = smallDateDay;
              lesson.smalldatemonth = smallDateMonth;
              lesson.datetime = startFormat + " tot " + endFormat;

              lesson.location = res.Locatie;
              lesson.description = res.Informatie;
              lesson.price = res.Prijs;
              lesson.participants = res.Huidig_aantal_inschrijvingen;
              lesson.available =
                res.Huidig_aantal_inschrijvingen < res.Maximum_inschrijvingen
                  ? true
                  : false;
              lesson.id = res.id;

              lessons.push(lesson);
            }
          });
          setLessons(lessons);
          setLoading(false);
        })
        .catch((err) => {
          setTemp(true);
          setLoading(false);
          setSuccess(true);
          console.log(err);
        });
    }
  }, [lessons, loading, success]);

  if (success === true) {
    return (
      <Container>
        <Helmet>
          <title>Aanbod lessen - Lili's sport</title>
          <meta
            name="description"
            content="Bekijk het lessen aanbod van lili's sport en schrijf je in om te sporten in een leuke omgeving."
          />
        </Helmet>
        {temp ? (
          <>
            <p className={styles.intro}>
              Schrijf je snel in op 1 van de lessen. De plaatsen zijn beperkt!
            </p>

            <h2>Wanneer</h2>
            <table className={styles.table_offer}>
              <tr>
                <td>23 april</td>
                <td>9:30 - 10:30</td>
              </tr>
              <tr>
                <td>30 april</td>
                <td>9:30 - 10:30</td>
              </tr>
              <tr>
                <td>7 mei</td>
                <td>9:30 - 10:30</td>
              </tr>
              <tr>
                <td>21 mei</td>
                <td>9:30 - 10:30</td>
              </tr>
              <tr>
                <td>28 mei</td>
                <td>9:30 - 10:30</td>
              </tr>
            </table>

            <p>Zin om een workout voor beginners te volgen?</p>
            <p>
              Schrijf u dan zeker in via e-mail op het adres:
              <br />
              <a href="mailto:fitmom.liesbeth.1981@gmail.com">
                fitmom.liesbeth.1981@gmail.com
              </a>
              , om aan deze GRATIS offline workout deel te nemen. Laten we samen
              werken aan een fittere versie van onszelf en/of van ons gezin!
              <br />
              <br />
              Meebrengen:
              <ul>
                <li>Matje</li>
                <li>Watertje</li>
                <li>Handdoekje</li>
                <li>Optioneel: gewichten en weerstandsband</li>
              </ul>
              Hopelijk zien we jullie daar!
            </p>
          </>
        ) : (
          <>
            <SubscribeModal
              lesson={activeLesson}
              open={activeLesson.id !== undefined ? true : false}
              onCancel={() => {
                setActiveLesson({});
              }}
            />

            <p className={styles.intro}>
              Schrijf je snel in op 1 van de lessen. De plaatsen zijn beperkt!
            </p>

            <div className={styles.aanbod}>
              {lessons.map((lesson) => {
                return (
                  <Card
                    smalldateday={lesson.smalldateday}
                    smalldatemonth={lesson.smalldatemonth}
                    key={lesson.id}
                    title={lesson.title}
                    datetime={lesson.datetime}
                    location={lesson.location}
                    description={lesson.description}
                    price={lesson.price}
                    available={lesson.available}
                    onClick={() => {
                      setActiveLesson(lesson);
                    }}
                  />
                );
              })}
            </div>
          </>
        )}
      </Container>
    );
  } else {
    return (
      <div className={styles.loadWrapper}>
        <Loader />
        <div>De lessen worden ingeladen...</div>
      </div>
    );
  }
};

export default Lessen;
