import styles from "./ImgCard.module.css";

const ImgCard = (props) => {
  return (
    <div key={props.gallerij.id} className={styles.gallerijitem}>
      <div className={styles.gallerijcontent}>
        <h2 className={styles.gallerijtitle}>{props.gallerij.Titel}</h2>
        <div className={styles.gallerijintro} dangerouslySetInnerHTML={{ __html: props.gallerij.Omschrijving }}></div>
      </div>
      <div className={styles.flexer}>
        <div>
          <img
            src={
              process.env.REACT_APP_BACKEND_URL +
              "assets/" +
              props.gallerij.Afbeelding1
            }
            alt={props.gallerij.Titel}
          />
        </div>
        <div>
          <img
            src={
              process.env.REACT_APP_BACKEND_URL +
              "assets/" +
              props.gallerij.Afbeelding2
            }
            alt={props.gallerij.Titel}
          />
        </div>
        <div>
          <img
            src={
              process.env.REACT_APP_BACKEND_URL +
              "assets/" +
              props.gallerij.Afbeelding3
            }
            alt={props.gallerij.Titel}
          />
        </div>
        <div>
          <img
            src={
              process.env.REACT_APP_BACKEND_URL +
              "assets/" +
              props.gallerij.Afbeelding4
            }
            alt={props.gallerij.Titel}
          />
        </div>
      </div>
      <div className={styles.readmore}>
        <a
          rel="noreferrer"
          target="_blank"
          href={props.gallerij.Link_naar_meer_fotos}
        >
          Bekijk meer foto's...
        </a>
      </div>
    </div>
  );
};

export default ImgCard;
