import styles from "./Header.module.css";

const Header = (props) => {
  return (
    <header
      className={styles.header}
      style={{ backgroundImage: 'url("' + props.backgroundImage + '")' }}
    >
      <h1>{props.title}</h1>
    </header>
  );
};

export default Header;
