import { Helmet } from "react-helmet-async";
import { useLocation } from "wouter";
import Button from "../../basics/button/Button";
import styles from "./Home.module.css";

const Home = ({ bgImage }) => {
  const [location, setLocation] = useLocation();
  console.log(location);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Een actieve levensstijl is noodzakelijk voor een goede gezondheid en mijn HIIT lessen zijn de ideale ondersteuning."
        />
      </Helmet>
      <div className={styles.homeHero}>
        <img src={bgImage} alt="lili's sport lessen" />
      </div>
      <div className={styles.homeAction}>
        <div className={styles.homeWrapper}>
          <div className={styles.homeMission}>
            <h1>
              Let's get <strong>fit</strong>
            </h1>
          </div>
          <div className={styles.homeButton}>
            <Button
              onClick={() => {
                setLocation("/lessen");
              }}
              primary={true}
              label="Schrijf je nu in"
            />
          </div>
          <div className={styles.homePresentation}>
            <p>Fitcoach Liesbeth</p>
            <p className={styles.small}>by Belgian Power Team</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
