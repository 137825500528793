import Button from "../../basics/button/Button";
import styles from "./Card.module.css";

const Card = (props) => {
  return (
    <div className={styles.card}>
      <div className={styles.carddate}>
        <div className={styles.dateday}>{props.smalldateday}</div>
        {props.smalldatemonth}
      </div>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.datetime}>{props.datetime}</div>
      <div className={styles.location}>
        <span className={styles.descriptor}>Locatie:</span>
        <br />
        {props.location}
      </div>
      <div className={styles.description}>
        <span className={styles.descriptor}>Omschrijving:</span>
        <br />

        <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
      </div>
      <div className={styles.price}>
        <span className={styles.descriptor}>Prijs:</span>
        <br />
        {props.price === 0 ? "Gratis" : props.price + " euro"}
      </div>
      <div className={styles.action}>
        <Button
          primary={true}
          disabled={!props.available}
          label={props.available ? "Inschrijven" : "Volzet"}
          onClick={() => {
            props.onClick();
          }}
        />
      </div>
    </div>
  );
};

export default Card;
