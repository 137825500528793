import { Directus } from "@directus/sdk";
import { useEffect, useState } from "react";
import { useLocation, useRoute } from "wouter";
import Container from "../../basics/container/Container";
import Loader from "../../basics/loader/Loader";
import Header from "../../elements/header/Header";
import styles from "./Blogitem.module.css";

const directus = new Directus(process.env.REACT_APP_BACKEND_URL);

const Blogitem = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [match, params] = useRoute("/blog/:id");
  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useLocation();
  const [item, setItem] = useState();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!loading && !success) {
      setLoading(true);
      directus
        .items("Blog")
        .readOne(params.id)
        .then((result) => {
          let blog = {};
          blog.title = result.Titel;
          blog.intro = result.Introductie;
          const createDate = new Date(result.date_created);
          blog.content = result.Inhoud;
          blog.createdDate = createDate;
          // const options = {day: "numeric", month: "short"};
          let smallDateDay = new Intl.DateTimeFormat("nl-NL", {
            day: "numeric",
          }).format(createDate);
          let smallDateMonth = new Intl.DateTimeFormat("nl-NL", {
            month: "short",
          }).format(createDate);
          blog.smalldateday = smallDateDay;
          blog.smalldatemonth = smallDateMonth;
          blog.id = result.id;
          setItem(blog);

          setSuccess(true);
          setLoading(false);
        });
    }
  }, [loading, success, location, params.id]);

  if (success === true) {
    return (
      <>
        <Header backgroundImage={props.backgroundImage} title={item.title} />
        <Container>
          <div className={styles.intro}>{item.intro}</div>
          <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
          <div
            onClick={() => {
              setLocation("/blog");
            }}
            className={styles.backbutton}
          >
            &lt; &lt; Terug
          </div>
        </Container>
      </>
    );
  } else {
    return (
      <div className={styles.loadWrapper}>
        <Loader />
        <div>Het artikel worden ingeladen...</div>
      </div>
    );
  }
};

export default Blogitem;
