import { Directus } from "@directus/sdk";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Container from "../../basics/container/Container";
import Loader from "../../basics/loader/Loader";
import styles from "./Privacy.module.css";

const directus = new Directus(process.env.REACT_APP_BACKEND_URL);

const Privacy = (props) => {
  const [policy, setPolicy] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!loading && !success) {
      setLoading(true);
      directus
        .singleton("Privacy_policy")
        .read()
        .then((result) => {
          setSuccess(true);
          setPolicy(
            result.policy
          );
          setLoading(false);
        }).catch(err => {
          setSuccess(true);
          setPolicy(
            "<h2>Algemene voorwaarden tijdelijk niet beschikbaar</h2><p>Er is iets fout gelopen bij het ophalen van de algemene voorwaarden.</p>"
          );
          setLoading(false);
          console.log(err);
        });
    }
  }, [policy, loading, success]);

  if (success === true) {
    return (
      <Container>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: policy }}></div>
      </Container>
    );
  } else {
    return (
      <div className={styles.loadWrapper}>
        <Loader />
        <div>De privacy policy en algemene voorwaarden/huisregels worden ingeladen...</div>
      </div>
    );
  }


};

export default Privacy;
