import { Directus } from "@directus/sdk";
import { useEffect, useState } from "react";
import { validateEmail } from "../../../utils/funcs/validateEmail";
import Button from "../../basics/button/Button";
import Loader from "../../basics/loader/Loader";
import styles from "./SubscribeModal.module.css";

const directus = new Directus(process.env.REACT_APP_BACKEND_URL);

const SubscribeModal = (props) => {
  const [naam, setNaam] = useState("");
  const [andere_namen, setAndereNamen] = useState("");
  const [e_mail, setEmail] = useState("");
  const [aantal_volwassenen, setVolwassenen] = useState(0);
  const [aantal_kinderen, setKinderen] = useState(0);
  const [voorwaarden_aanvaard, setVoorwaarden] = useState(false);
  const [error, setError] = useState([]);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateInput = () => {
    let errors = [];
    if (naam.trim() === "") {
      errors.push("Naam is verplicht.");
    }
    if (e_mail.trim() === "" || !validateEmail(e_mail)) {
      errors.push("E-mail is verplicht en moet een correct e-mail adres zijn.");
    }
    if (aantal_volwassenen + aantal_kinderen === 0) {
      errors.push("Minimum 1 volwassene of kind moet ingeschreven worden.");
    }

    if (voorwaarden_aanvaard === false) {
      errors.push("De algemene voorwaarden moeten aanvaard worden.");
    }
    return errors;
  };

  const subscribeData = () => {
    let subscription = {};
    subscription.evenement = props.lesson.id;
    subscription.naam = naam;
    subscription.e_mail = e_mail;
    subscription.aantal_volwassenen = aantal_volwassenen;
    subscription.aantal_kinderen = aantal_kinderen;
    subscription.andere_namen = andere_namen;
    subscription.betaald = props.lesson.price === 0 ? true : false;
    subscription.voorwaarden_aanvaard = voorwaarden_aanvaard;
    return directus.items("Inschrijvingen").createOne(subscription);
  };
  const updateEventData = () => {
    let event = {};
    event.Huidig_aantal_inschrijvingen = parseInt(props.lesson.participants) + parseInt(aantal_volwassenen) + parseInt(aantal_kinderen);
    return directus.items("Evenementen").updateOne(props.lesson.id, event);
  };
  const subscibeNow = () => {
    const formErrors = validateInput();
    setError(formErrors);
    if (formErrors.length === 0) {
      setLoading(true);
      // validated
      Promise.all([subscribeData(), updateEventData()])
        .then((results) => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setError([
            "Er is iets verkeerd gelopen bij inschrijven. Probeer later opnieuw.",
          ]);
        });
    }
  };

  useEffect(() => {
    if (props.open === false) {
      setError([]);
      setEmail("");
      setNaam("");
      setAndereNamen("");
      setVolwassenen(0);
      setKinderen(0);
      setSuccess(false);
      setLoading(false);
      setVoorwaarden(false);
    }
  }, [props.open]);

  if (props.open === true) {
    if (loading === true || success === true) {
      return (
        <div className={styles.modal}>
          <div className={styles.content}>
            <h2>{props.lesson.title}</h2>
            <div className={styles.flexer}>
              <div>
                <label>Datum</label>
                <br />
                {props.lesson.smalldateday + " " + props.lesson.smalldatemonth}
                <br />
                {props.lesson.datetime}
              </div>
              <div>
                <label>Locatie</label>
                <br />
                {props.lesson.location}
              </div>
              <div>
                <label>Prijs</label>
                <br />
                {"€ " + props.lesson.price}
              </div>
            </div>
            {loading === true ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : null}

            {success === true ? (
              <>
                <p>
                  <strong>U bent ingeschreven!</strong>
                </p>
                <div className={styles.actions}>
                  <div>
                    <Button
                      primary={false}
                      label="sluiten"
                      onClick={() => {
                        props.onCancel();
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.modal}>
        <div className={styles.content}>
          <h2>{props.lesson.title}</h2>
          <div className={styles.flexer}>
            <div>
              <label>Datum</label>
              <br />
              {props.lesson.smalldateday + " " + props.lesson.smalldatemonth}
              <br />
              {props.lesson.datetime}
            </div>
            <div>
              <label>Locatie</label>
              <br />
              {props.lesson.location}
            </div>
            <div>
              <label>Prijs</label>
              <br />
              {"€ " + props.lesson.price}
            </div>
          </div>

          <div>
            <label htmlFor="naam">Naam</label>
            <input
              type="text"
              id="naam"
              className={styles.input}
              defaultValue={naam}
              onChange={(e) => {
                setNaam(e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="e_mail">E-mail</label>
            <input
              type="email"
              id="e_mail"
              className={styles.input}
              defaultValue={e_mail}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className={styles.flexer}>
            <div>
              <label htmlFor="aantal_volwassenen">Aantal volwassenen</label>
              <input
                type="number"
                id="aantal_volwassenen"
                className={styles.input}
                defaultValue={aantal_volwassenen}
                onChange={(e) => {
                  setVolwassenen(e.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="aantal_kinderen">Aantal kinderen</label>
              <input
                type="number"
                id="aantal_kinderen"
                className={styles.input}
                defaultValue={aantal_kinderen}
                onChange={(e) => {
                  setKinderen(e.target.value);
                }}
              />
            </div>
          </div>
          {aantal_kinderen + aantal_volwassenen > 1 ? (
            <div>
              <label htmlFor="e_mail">Namen andere personen (gesplitst met komma)</label>
              <input
                type="text"
                id="andere_namen"
                className={styles.input}
                defaultValue={andere_namen}
                onChange={(e) => {
                  setAndereNamen(e.target.value);
                }}
              />
            </div>
          ) : null}
          <div>
            <input
              type="checkbox"
              id="voorwaarden_aanvaard"
              className={styles.checkbox}
              defaultValue={voorwaarden_aanvaard}
              onChange={(e) => {
                setVoorwaarden(e.target.checked);
              }}
            />
            <label htmlFor="voorwaarden_aanvaard">
              Bij deze aanvaard ik de algemene voorwaarden en ben me ervan
              bewust dat de organisator van Lili's Sports niet aansprakelijk kan
              gesteld worden bij eventuele schade.
            </label>
          </div>
          {error.length > 0
            ? error.map((err, i) => {
                return (
                  <p key={"err" + i} className={styles.error}>
                    {err}
                  </p>
                );
              })
            : null}
          <div className={styles.actions}>
            <Button
              primary={true}
              label="Inschrijven"
              onClick={() => {
                subscibeNow();
              }}
            />
            <div>
              <Button
                primary={false}
                label="Annuleren"
                onClick={() => {
                  props.onCancel();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default SubscribeModal;
