import { Directus } from "@directus/sdk";
import { useEffect, useState } from "react";
import Container from "../../basics/container/Container";
import Loader from "../../basics/loader/Loader";
import ImgCard from "../../elements/imgcard/ImgCard";

import styles from "./Gallerij.module.css";

const directus = new Directus(process.env.REACT_APP_BACKEND_URL);

const Gallerij = (props) => {
  const [galleries, setGalleries] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!loading && !success) {
      setLoading(true);
      directus
        .items("Gallerij")
        .readByQuery({ limit: 5, sort: "-date_created" })
        .then((result) => {
          setSuccess(true);
          setGalleries(result.data);
          setLoading(false);
        });
    }
  }, [galleries, loading, success]);
  if (success === true) {
    return (
      <Container>
        {galleries.map((gallery) => {
          return <ImgCard key={gallery.id} gallerij={gallery} />;
        })}
      </Container>
    );
  } else {
    return (
      <div className={styles.loadWrapper}>
        <Loader />
        <div>De gallerij worden ingeladen...</div>
      </div>
    );
  }
};

export default Gallerij;
