import { Link } from "wouter";
import styles from "./Nav.module.css";

const Nav = () => {
  return (
    <nav className={styles.nav}>
      <div className={styles.navWrapper}>
        <div className={styles.logo}>Lili's</div>
        <ul className={styles.navList}>
          <li>
            <Link href="/">Home</Link>
          </li>
          <li>
            <Link href="/lessen">Aanbod</Link>
          </li>
          <li>
            <Link href="/gallerij">Gallerij</Link>
          </li>
          <li>
            <Link href="/blog">Blog</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
